import "./utils/themes";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";

import ScrollToTopWrapper from "components/common/ScrollToTopWrapper";
import App from "./components/App";

import store, { sagaMiddleware } from "./store";

import rootSaga from "./sagas";

import "./utils/errorReporting";
import bootstrap from "bootstrap";

import "./styles/index.scss";

document.title = `${bootstrap.title2} • ${bootstrap.name}`;

sagaMiddleware.run(rootSaga);

const root = document.getElementById("root");

root && ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ScrollToTopWrapper>
        <Route component={App} />
      </ScrollToTopWrapper>
    </Router>
  </Provider>,
  root
);

(function() {
  const { abs, min, max, pow, exp } = Math;
  // Variables for touch-based scrolling
  let lastY, lastX, lastVX, lastVY, isTouching = false, timestamp;
  let velocities, velocitiesIdx;
  let animationFrame;
  
  let vStack, hStack;
  
  const scrollableOverflows = new Set(["auto", "scroll"]);
  const isScrollableVertically = el => el.tagName !== "TD" && el.tagName !== "TR" && el.scrollHeight > el.clientHeight && scrollableOverflows.has(getComputedStyle(el).overflowY);
  const isScrollableHorizontally = el => el.tagName !== "TD" && el.tagName !== "TR" && el.scrollWidth > el.clientWidth && scrollableOverflows.has(getComputedStyle(el).overflowX);
  
  let vScrollable, hScrollable;
  
  function findScrollables($initial) {
    const vs = [], hs = [];
    let $el = $initial;
    while ($el) {
      if (isScrollableVertically($el)) vs.push($el);
      if (isScrollableHorizontally($el)) hs.push($el);
      $el = $el.parentElement;
    }
    vStack = vs;
    hStack = hs;
  }
  
  function scrollStack(stack, by, clientHeight, scrollHeight, scrollTop) {
    let $last = null;
    for (let i = 0; i < stack.length && abs(by) >= 1; i++) {
      const $el = stack[i];
      if (by > 0) {
        const h = max(0, $el[scrollHeight] - $el[scrollTop] - $el[clientHeight]);
        // console.log("sV+", h, min(h, by), $el);
        if (h > 0) {
          const x = min(h, by);
          by -= x;
          $el[scrollTop] += x
          $last = $el;
        }
      }
      else if (by < 0) {
        const h = $el[scrollTop];
        // console.log("sV-", h, min(h, -by), $el);
        if (h > 0) {
          const x = min(h, -by);
          by += x;
          $el[scrollTop] -= x
          $last = $el;
        }
      }
    }
    return $last;
  }
  
  let state;
  let initialTimestamp;

  document.addEventListener("touchstart", function (e) {
    if (e.touches.length !== 1) {
      isTouching = false;
      return;
    }
    
    const { pageX, pageY } = e.touches[0];
    
    findScrollables(e.target);
    
    // Record touch start positions
    lastVY = lastY = pageY;
    lastVX = lastX = pageX;
    accX = 0; accY = 0;
    movingX = movingY = false;
    isTouching = true;
    velocities = [];
    velocitiesIdx = 0;
    initialTimestamp = timestamp = performance.now()
    
    cancelAnimationFrame(animationFrame);
    //e.preventDefault();
  });
  
  const SENSITIVITY = window.innerWidth / 10;
  let ts = performance.now();
  let movingX = false, movingY = false;
  let accX = 0, accY = 0;
  let scrollAnimationFrame = null;
  
  document.addEventListener("touchmove", function (e) {
    if (!isTouching) return;
    
    const { pageX, pageY } = e.touches[0];
    
    const deltaY = pageY - lastY;
    const deltaX = pageX - lastX;
    
    const last = timestamp;
    timestamp = performance.now();
    
    if (!movingY && abs(deltaY) >= SENSITIVITY * (1 + +movingX)) { movingY = true; }
    if (!movingX && abs(deltaX) >= SENSITIVITY * (1 + +movingY)) { movingX = true; }
    
    let velX = 0;
    let velY = 0;
    
    // Update scroll positions of the detected scrollable element
    if (movingX) {
      // hScrollable = scrollStack(hStack, -deltaX, "clientWidth", "scrollWidth", "scrollLeft");
      accX -= deltaX;
      velX = pageX - lastVX;
      lastX = pageX;
      if (!scrollAnimationFrame)
        scrollAnimationFrame = requestAnimationFrame(deferredScroll);
    }
    
    // console.log("touch", movingY, accY, velocityY);
    
    if (movingY) {
      // vScrollable = scrollStack(hStack, -deltaY, "clientHeight", "scrollHeight", "scrollTop");
      accY -= deltaY;
      velY = pageY - lastVY;
      lastY = pageY;
      if (!scrollAnimationFrame)
        scrollAnimationFrame = requestAnimationFrame(deferredScroll);
    }
    
    lastVY = pageY;
    lastVX = pageX;
    
    if (velocities.length < 4)
      velocities.push([velX, velY, timestamp]);
    else {
      const V = velocities[velocitiesIdx];
      V[0] = velX;
      V[1] = velY;
      V[2] = timestamp;
    }
    velocitiesIdx = (velocitiesIdx + 1) & 3;
    
    e.preventDefault();
  });
  
  function deferredScroll() {
    scrollAnimationFrame = null;
    
    if (abs(accX) > 1) {
      hScrollable = scrollStack(hStack, accX, "clientWidth", "scrollWidth", "scrollLeft");
      accX = 0;
    }
    
    if (abs(accY) > 1) {
      vScrollable = scrollStack(vStack, accY, "clientHeight", "scrollHeight", "scrollTop");
      accY = 0;
    }
  }
  
  function calculateVelocity() {
    if (velocities.length < 1)
      return [0, 0];
    let earliest = 1 << 52;
    let x = 0, y = 0;
    for (let i = 0; i < velocities.length; i++) {
      const v = velocities[i];
      x += v[0];
      y += v[1];
      if (v[2] < earliest)
        earliest = v[2];
    }
    const now = performance.now();
    const it = 1 / (now - earliest);
    x *= it; y *= it;
    // console.log("V", x, y)
    return [x, y];
  }

// Touch end handler to apply kinetic scrolling
  document.addEventListener("touchend", function () {
    // console.log("touchend")
    isTouching = false;
    
    let scrollable;
    let prop //as keyof Element;
    let velocity //as number;
    const [velocityX, velocityY] = calculateVelocity();
    if (abs(velocityY) >= abs(velocityX) && vScrollable) {
      scrollable = vScrollable;
      prop = "scrollTop";
      velocity = velocityY;
    }
    else if (hScrollable) {
      scrollable = hScrollable;
      prop = "scrollLeft";
      velocity = velocityX;
    }
    else {
      return;
    }
    
    const start = timestamp;
    let lastTick = timestamp;
    function kineticScroll() {
      const now = performance.now()
      const elapsed = (now - start);
      const deceleration = exp(-0.003 * elapsed);
      const distance = velocity * deceleration * (now - lastTick);
      
      // console.log("dV", distance, elapsed, velocity, deceleration, now - lastTick)
      
      lastTick = now;
      
      if (abs(distance) < 1) return;
      
      //emitScrollEvent(scrollable);
      scrollable[prop] -= distance;
      
      // Continue animation loop
      animationFrame = requestAnimationFrame(kineticScroll);
    }
    
    animationFrame = requestAnimationFrame(kineticScroll);
  });
})();